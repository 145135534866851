function closeMenus() {
  closeTargets = document.querySelectorAll(
    '.global-nav__fadein--target.active, .global-nav__fadein--trigger.active'
  );

  for (let c of closeTargets) {
    c.classList.remove('active');
  }
}

function toggleMainMenuElement(triggerEl, menuEl) {
  if (triggerEl.classList.contains('active')) {
    triggerEl.classList.remove('active');
    menuEl.classList.remove('active');
    return;
  } else {
    closeMenus();
  }

  triggerEl.classList.add('active');
  menuEl.classList.add('active');
}

document.addEventListener('DOMContentLoaded', () => {
  let menuTriggerBtnEl = document.getElementById('nav-menu');
  let menuEl = document.getElementsByClassName('global-nav--overflow')[0];
  let notificationTriggerBtnEl = document.getElementById('nav-notifications');
  let notificationEl = document.getElementById('notifications-box');

  const clickableElements = [
    menuTriggerBtnEl,
    menuEl,
    notificationTriggerBtnEl,
    notificationEl
  ];

  if (menuTriggerBtnEl) {
    menuTriggerBtnEl.addEventListener('click', () =>
      toggleMainMenuElement(menuTriggerBtnEl, menuEl)
    );
  }

  if (notificationTriggerBtnEl) {
    notificationTriggerBtnEl.addEventListener('click', () =>
      toggleMainMenuElement(notificationTriggerBtnEl, notificationEl)
    );

    let notificationCloseButton = document.getElementsByClassName(
      'dropdown-box__button--close'
    )[0];

    notificationCloseButton.addEventListener('click', () =>
      toggleMainMenuElement(notificationTriggerBtnEl, notificationEl)
    );
  }

  document.addEventListener('click', e => {
    closeMenus();
  });

  for (let c of clickableElements) {
    if (c) c.addEventListener('click', e => e.stopPropagation());
  }
});
